<template>
  <div>
    <sovi-bread-crumbs class="pb-2" />

    <sovi-card :title="t('title')">
      <ul v-auto-animate class="grid gap-[6px] overflow-hidden">
        <li
          v-if="!templates.length"
          class="rounded-xl bg-dark-500/5 px-4 py-2 text-sm dark:bg-white/10"
        >
          {{ t('noTemplates') }}
        </li>

        <li
          v-for="template in templates"
          :key="template.id"
          class="relative flex min-h-12 cursor-pointer items-center justify-between gap-2 rounded-md border p-2 transition-colors hover:bg-light-300 dark:border-dark-600 hover:dark:bg-dark-600"
          tabindex="0"
          @keyup.enter="onEdit(template)"
          @click="onEdit(template)"
        >
          <div class="grid size-10 place-content-center">
            <div
              v-if="isLoading(`getTemplate--${template.id}`)"
              class="sovi-contract-list-item__content__loader"
            >
              <v-progress-circular
                color="tertiary"
                width="2"
                size="24"
                indeterminate
              />
            </div>

            <v-icon v-else color="tertiary">mdi-card-text-outline</v-icon>
          </div>

          <div class="grid flex-1 gap-1">
            <p class="font-semibold">{{ template.name }}</p>

            <p class="opacity-50">
              {{ t('updatedAt') }}
              {{
                formatDate(template.updated_at, {
                  locale: $i18n.locale,
                  type: 'full',
                })
              }}
            </p>
          </div>
        </li>
      </ul>

      <template #actions>
        <!-- TODO:  remove this -->
        <v-btn class="hidden" color="" @click="onExport()">Export to PDF</v-btn>

        <v-spacer />

        <v-btn
          size="default"
          variant="elevated"
          prepend-icon="mdi-plus"
          @click="onCreateNewTemplate()"
        >
          {{ t('create') }}
        </v-btn>
      </template>
    </sovi-card>

    <sovi-template-create-dialog
      :show="showCreateDialog"
      @close="onCreateDialogClose()"
    />

    <sovi-template-edit-dialog
      v-if="editedTemplate?.id"
      :template="editedTemplate"
      :show="showEditDialog"
      :html="templateContent"
      edit
      @close="onEditDialogClose()"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils';

import pdfMake from 'pdfmake/build/pdfmake';
import htmlToPdfmake from 'html-to-pdfmake';
import { storeToRefs } from 'pinia';
import { useTemplateStore } from '@/stores/template';
import type { ContractTemplate } from '@/types/template';
import { useLoadingStore } from '@/stores/loading';
import { useContractStore } from '@/stores/contract';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'TemplateManagement',
  meta: {
    hero: true,
    restricted: true,
    preloaders: ['preloadTemplates'],
  },
});

onBeforeMount(() => {
  setBreadCrumbs([
    {
      id: 'TemplateManagement',
      href: '/templates/management',
      name: t('title'),
      disabled: true,
    },
  ]);
});

const { isLoading } = useLoadingStore();

const { getTemplate } = useTemplateStore();

const { templates } = storeToRefs(useTemplateStore());

const { setBreadCrumbs } = useContractStore();

const { t } = useI18n();

const templateContent = ref('');

const showCreateDialog = ref(false);

const showEditDialog = ref(false);

const editorContainerRef = ref<HTMLDivElement | null>(null);

const onExport = async () => {
  pdfMake.fonts = {
    Quicksand: {
      normal: 'https://rmr.fi/fonts/Quicksand-Regular.ttf',
      bold: 'https://rmr.fi/fonts/Quicksand-Bold.ttf',
      italics: 'https://rmr.fi/fonts/Quicksand-Regular.ttf',
      bolditalics: 'https://rmr.fi/fonts/Quicksand-Bold.ttf',
    },
  };

  const html = setPdfWidths(templateContent.value);

  const content = htmlToPdfmake(html, {
    // @ts-ignore
    defaultStyles: { img: { width: 515.28 } },
  });

  pdfMake
    .createPdf({
      content,
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      defaultStyle: {
        font: 'Quicksand',
      },
    })
    .download();
};

const onCreateNewTemplate = () => {
  showCreateDialog.value = true;
};

const onCreateDialogClose = () => {
  showCreateDialog.value = false;
};

/**
 * Sets the widths of images in an HTML string to match a PDF document.
 *
 * @param {string} html - The HTML string to modify.
 * @return {string} The modified HTML string.
 */
const setPdfWidths = (html: string) => {
  // Width of a standard A4 PDF page
  const pdfWidth = 595.28;

  // Parse the HTML string into a document object
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Get all images in the document
  const imgs = doc.getElementsByTagName('img');

  // Loop through each image
  Array.from(imgs).forEach((img) => {
    // Get the height of the image
    const height = +(img.getAttribute('height') ?? '0');

    // Calculate the aspect ratio of the image
    const aspectRatio = +(img.getAttribute('width') ?? '0') / height;

    // Calculate the scaling factor to match the PDF page width
    const factor =
      +(img.getAttribute('width') ?? '0') /
        (editorContainerRef.value?.getBoundingClientRect().width || 0) +
      0.16;

    // Calculate the new width and height of the image
    const newWidth = Math.floor(factor * pdfWidth);
    const newHeight = Math.floor(newWidth / aspectRatio);

    // Update the image attributes with the new width and height
    img.setAttribute('width', newWidth.toString());
    img.setAttribute('height', newHeight.toString());
  });

  // Return the modified HTML string
  return doc.documentElement.outerHTML;
};

const editedTemplate = ref<ContractTemplate | null>(null);

const onEdit = async (template: ContractTemplate) => {
  editedTemplate.value = template;

  templateContent.value = await getTemplate(template.id);

  showEditDialog.value = true;
};

const onEditDialogClose = () => {
  showEditDialog.value = false;

  editedTemplate.value = null;
};
</script>

<i18n lang="json">
{
  "fi": {
    "title": "Asiakirjamallien hallinta",
    "create": "Luo uusi asiakirjamalli",
    "placeholder": "Luo uusi asiakirjamalli...",
    "updatedAt": "Muokattu viimeksi",
    "noTemplates": "Ei asiakirjamalleja",
    "toolbar": {
      "size": {
        "small": "Pieni",
        "normal": "Normaali",
        "large": "Suuri",
        "huge": "Suurin"
      },
      "header": {
        "1": "Otsikko 1",
        "2": "Otsikko 2",
        "normal": "Normaali"
      }
    }
  },
  "en": {
    "title": "Template management",
    "create": "Create a new template",
    "placeholder": "Compose a new contract template...",
    "updatedAt": "Last updated on",
    "noTemplates": "No templates",
    "toolbar": {
      "size": {
        "small": "Small",
        "normal": "Normal",
        "large": "Large",
        "huge": "Huge"
      },
      "header": {
        "1": "Heading 1",
        "2": "Heading 2",
        "normal": "Normal"
      }
    }
  }
}
</i18n>
