import Client from '@/repositories/clients/fetchClient';
import type {
  DeleteDocumentPayload,
  GetAllLogosResponse,
  GetDocumentsResponse,
  GetUrlsResponse,
  GetVerifiedDocumentsResponse,
  LogoScheme,
} from '@/types/document';

const resource = 'documents';

export const documentRepository = {
  createLogo: (scheme: LogoScheme, companyId: number, payload: File) =>
    Client.post(`${resource}/logo/${scheme}/${companyId}`, payload),

  delete: (payload: DeleteDocumentPayload) =>
    Client.delete<{ success: boolean }>(resource, payload),

  getAll: (documentId: string) =>
    Client.get<GetDocumentsResponse>(`${resource}/${documentId}`),

  getAllLogos: (companyId: number) =>
    Client.get<GetAllLogosResponse>(`${resource}/logo/${companyId}`),

  getOne: (contractId: string, documentId: number) =>
    Client.get<BlobPart>(`${resource}/download/${contractId}/${documentId}`),

  getUrls: (contractId: string, ids: string) =>
    Client.get<GetUrlsResponse>(`${resource}/${contractId}/${ids}`),

  getVerifiedDocuments: (contractId: string) =>
    Client.get<GetVerifiedDocumentsResponse>(
      `${resource}/verify/${contractId}`,
    ),

  // update: (id, payload) => Client.put(resource, payload),
};
