<template>
  <v-menu
    close-on-content-click
    transition="slide-y-reverse-transition"
    content-class="sovi-menu"
    offset-y
    location="bottom right"
    attach
  >
    <template #activator="{ props }">
      <v-btn
        class="m-2"
        color="primary"
        text-color="white"
        size="default"
        rounded
        v-bind="props"
      >
        <template #prepend>
          <v-icon left>mdi-circle-multiple-outline</v-icon>
        </template>

        {{ credit }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ t('credits') }}</v-card-title>

      <v-card-text>
        <v-icon v-if="credit === 0" class="mr-2" color="warning" size="16">
          mdi-alert
        </v-icon>

        {{ t('creditsLeft', { credit }, credit || 0) }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="isLoading('getPackages')"
          :loading="isLoading('getPackages')"
          color="success"
          size="default"
          variant="tonal"
          block
          @click="onBuyMore()"
        >
          {{ t('buyMore') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { useCreditStore } from '@/stores/credit';
import { useLoadingStore } from '@/stores/loading';
import { usePackageStore } from '@/stores/package';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { isLoading } = useLoadingStore();

const { getPackages, showPackageDialog } = usePackageStore();

const { total: credit } = storeToRefs(useCreditStore());

const onBuyMore = async () => {
  await getPackages();

  showPackageDialog();
};
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "credits": "Allekirjoituskrediitit",
    "creditsLeft": "{credit} allekirjoituskrediitti jäljellä | {credit} allekirjoituskrediittiä jäljellä",
    "buyMore": "Osta"
  },
  "en": {
    "credits": "Signature credits",
    "creditsLeft": "{credit} signature credit left | {credit} signature credits left",
    "buyMore": "Buy"
  }
}
</i18n>
