<template>
  <div :style="style" class="sovi-stripe" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    height?: number;
  }>(),
  { height: 2 },
);

const style = computed(() => ({
  height: `${Number(props.height)}px`,
}));
</script>

<style lang="scss">
.sovi-stripe {
  background: linear-gradient(
    to right,
    rgb(var(--v-theme-primary)),
    rgb(var(--v-theme-quaternary)),
    rgb(var(--v-theme-tertiary))
  );

  background-size: 600% 600%;
  animation: gradient-animation 25s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
