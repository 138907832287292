<template>
  <sovi-card :title="t('verifyYourDocuments')">
    <p>{{ t('description') }}</p>

    <sovi-document-verifier class="mt-4" />
  </sovi-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'Verify',
  meta: {
    restricted: false,
  },
});

const { t } = useI18n();
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "verifyYourDocuments": "Tarkista allekirjoitusten aitous",
    "description": "Voit tarkistaa Sovi.fi-palvelussa tehtyjen allekirjoitusten aitouden pudottamalla tiedoston alla olevaan laatikkoon. Huomaa että tarkistukseen tulee käyttää sopimuskohtaista uniikkia tarkistuslinkkiä joka löytyy PDF-tiedoston allekirjoitussivulta."
  },
  "en": {
    "verifyYourDocuments": "Verify signature authenticity",
    "description": "You can verify the signatures made in Sovi.fi service by dragging and dropping the file into the box below. Note that you will need to click on the contract specific unique link provided in the signature page of the PDF file to verify the authenticity of the document."
  }
}
</i18n>
