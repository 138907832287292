<template>
  <v-card class="sovi-dialog__card z-1 !rounded-xl">
    <v-card-title
      v-if="title"
      class="overflow-visible whitespace-normal p-4 text-xl md:p-6 md:text-2xl"
    >
      {{ title }}
    </v-card-title>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-card-actions
      v-if="slots.actions"
      class="flex flex-wrap gap-2 rounded-b-xl"
      :class="{ 'sticky bottom-0': stickyActions }"
    >
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    title?: string;
    stickyActions?: boolean;
  }>(),
  {
    title: '',
    stickyActions: false,
  },
);

const slots = defineSlots<{ default: any; actions: any }>();
</script>

<style lang="scss"></style>
