import { useRequestStore } from '@/stores/request';
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';

type Method = 'get' | 'post' | 'put' | 'delete';

type Payload = Record<string, unknown> | File | FormData;

const baseDomain = import.meta.env.VITE_API_URL;

const fileTypes = ['application/pdf', 'text/html'];

export const baseURL = `${baseDomain}/api/v1`;

/**
 * Adds requests to store to make them cancelable
 *
 * @param {String} request - The request URL
 * @param {Object} opts - The request options
 */
const abortableFetch = (url: string, opts: Payload) => {
  const controller = new AbortController();
  const { signal } = controller;
  const { addRequest } = useRequestStore();

  addRequest(url, controller);

  return fetch(url, { ...opts, signal });
};

const isFile = (response: Response) =>
  fileTypes.includes(response.headers.get('Content-type') || '');

const handleErrors = (response: Response) => {
  if (response.status === 401) {
    const { showExpirationDialog } = storeToRefs(useAppStore());

    showExpirationDialog.value = true;
  }

  if (!response.ok || response.status > 399) {
    console.error(response);

    throw new Error('Failed to fetch');
  }
};

/**
 * Makes a fetch request to the specified URL with the given options,
 * handles errors, and returns the response.
 *
 * @template T - the type of the data expected in the response
 * @param {string} url - the URL to make the request to
 * @param {Payload} [options={}] - the options for the request
 * @returns
 * - a promise that resolves to an object with a success boolean and
 * the data from the response
 * - an error if one occurred
 */
const query = async <T>(url: string, options: Payload = {}) => {
  let response = null;

  const { removeRequest } = useRequestStore();

  try {
    response = await abortableFetch(url, options);

    handleErrors(response);

    return {
      success: true,
      data: await (isFile(response)
        ? (response.blob() as T)
        : (response.json() as T)),
    };
  } catch (error) {
    return {
      success: false,
      data: {} as T,
      error,
    };
  } finally {
    // remove request on completion
    removeRequest(url);
  }
};

/**
 * Generate the params for a fetch query
 *
 * @param {String} method - http method to be used
 * @param {Object} payload
 *
 * @returns {Object}      - params for a query
 */
const createParams = (
  method: Method,
  payload: Payload,
  contentType = 'application/json',
) => ({
  method: method.toUpperCase(),
  headers: {
    Accept: 'application/json',
    'Content-Type': contentType,
    Authorization: localStorage.getItem('sovi-token') || '',
    Identifier: localStorage.getItem('sovi-identifier') || '',
  },
  body: contentType === 'application/json' ? JSON.stringify(payload) : payload,
  credentials: 'include',
});

/**
 * An API to do queries to the back end
 */
export default {
  get: <T>(url: string): ReturnType<typeof query<T>> =>
    query<T>(`${baseURL}/${url}`, {
      credentials: 'include',
      headers: {
        Authorization: localStorage.getItem('sovi-token') || '',
        Identifier: localStorage.getItem('sovi-identifier') || '',
      },
    }),
  post: <T>(
    url: string,
    payload: Payload,
    contentType?: string,
  ): ReturnType<typeof query<T>> =>
    query<T>(`${baseURL}/${url}`, createParams('post', payload, contentType)),
  put: <T>(url: string, payload: Payload): ReturnType<typeof query<T>> =>
    query<T>(`${baseURL}/${url}`, createParams('put', payload)),
  delete: <T>(url: string, payload?: Payload): ReturnType<typeof query<T>> =>
    query<T>(`${baseURL}/${url}`, createParams('delete', payload || {})),
};
