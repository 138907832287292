import { ref } from 'vue';
import { defineStore } from 'pinia';
import { netsRepository } from '@/repositories';
import { useLoadingStore } from './loading';

export const useNetsStore = defineStore('nets', () => {
  const { startLoading, endLoading } = useLoadingStore();

  const personalInformation = ref({});

  const identificationDialog = ref<string | null>(null);

  const showIdentificationDialog = async (payload: {
    contractId: string | null;
  }) => {
    identificationDialog.value = payload.contractId;
  };

  const getLink = async ({ contractId }: { contractId: string }) => {
    try {
      startLoading('getLink');
      const { data, error, success } = await netsRepository.getLink(contractId);

      if (!success) throw error;

      endLoading('getLink');

      return data;
    } catch (error) {
      endLoading('getLink');

      console.error(error);

      return { link: '' };
    }
  };

  const getIdToken = async ({
    code,
    state,
  }: {
    code: string;
    state: string;
  }) => {
    try {
      const { data, error, success } = await netsRepository.getIdToken(
        code,
        state,
      );

      if (!success) throw error;

      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  return {
    personalInformation,
    identificationDialog,
    showIdentificationDialog,
    getLink,
    getIdToken,
  };
});
