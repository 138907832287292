import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export const createSentryInstance = (app: App) => {
  const { VITE_SENTRY_DSN } = import.meta.env;

  if (!VITE_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/sovi\.fi/],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
