// @ts-expect-error
import { usePdfjs } from 'pdf-component-vue';
import type { App } from 'vue';

export const usePdfjsPlugin = async (app: App<Element>) => {
  const workerUrl = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  );

  const bundleV4 = {
    pdfjs: await import(
      /* @vite-ignore */ new URL(
        'pdfjs-dist/build/pdf.min.mjs',
        import.meta.url,
      ) as unknown as string
    ),
    viewer: await import(
      /* @vite-ignore */ new URL(
        'pdfjs-dist/web/pdf_viewer.mjs',
        import.meta.url,
      ) as unknown as string
    ),
  };

  bundleV4.pdfjs.GlobalWorkerOptions.workerSrc = workerUrl.toString();

  usePdfjs(app, bundleV4);
};
