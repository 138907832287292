import '@mdi/font/css/materialdesignicons.css';
// import '@/styles/vuetify.scss';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { md3 } from 'vuetify/blueprints';
import { VBtn } from 'vuetify/components/VBtn';

const colors = {
  tertiary: '#eca1c2',
  // primary: '#ffd71e',
  // primary: '#F5A202',
  secondary: '#191100', // no change
  primary: '#ffe870',
  quaternary: '#5cdb95',
  quinary: '#BB00E1',
  senary: '#72DEFF',
  success: '#5cdb95',
  anchor: '#eca1c2',
};

const cardDefaults = (menu = false) => ({
  VCard: {
    class: `${menu ? 'dark:!bg-dark-700 dark:!border-dark-600' : 'dark:!bg-dark-800 dark:!border-gray-600/90'} !border !border-light-300 !bg-white !shadow-xl !dark:shadow-dark-950/75 !rounded-2xl`,

    VCardTitle: {
      class: `p-4 md:p-6 ${menu ? 'text-xl' : 'text-2xl'}`,
    },

    VCardText: {
      class: '!px-4 !pb-4 md:!px-6 md:!pb-6 !pt-1',
    },

    VCardActions: {
      class: `${menu ? 'dark:border-t-dark-800 dark:bg-dark-600' : 'dark:border-t-dark-900 dark:bg-dark-700'} border-t border-t-light-400 bg-light-200 p-4 md:px-6`,
    },
  },
});

export default createVuetify({
  aliases: {
    VIconBtn: VBtn,
  },

  defaults: {
    ...cardDefaults(),

    VIconBtn: {
      variant: 'text',
      class: '!rounded-full',
      density: 'compact',
      size: 'large',
    },

    VProgressLinear: {
      rounded: true,
    },

    VMenu: {
      scrollStrategy: 'none',

      ...cardDefaults(true),

      VList: {
        class: '!bg-white dark:!bg-dark-700',
      },
    },

    VBtn: {
      class: 'px-4 normal-case !mx-0',
      size: 'large',
      rounded: true,
      variant: 'elevated',
    },

    VTextField: {
      rounded: true,
      variant: 'outlined',
    },

    VDialog: {
      scrim: 'transparent',
      VOverlay: {
        class: 'backdrop-blur-sm',
      },
    },
  },
  theme: {
    variations: {
      lighten: 5,
      darken: 4,
      colors: ['primary', 'secondary', 'background'],
    },

    themes: {
      dark: {
        dark: true,
        colors: {
          background: '#090b0d',
          ...colors,
          senary: colors.tertiary,
          'on-tertiary': '#000000',
        },
      },

      light: {
        dark: false,
        colors: {
          background: '#eff2f2',
          ...colors,
          senary: '#d491af',
          anchor: '#d491af',
          quaternary: '#54c586',
          success: '#54c586',
          'on-tertiary': '#000000',
        },
      },
    },
  },

  blueprint: md3,

  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },

  display: {
    mobileBreakpoint: 756,
  },
});
