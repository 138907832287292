<template>
  <v-fade-transition mode="out-in">
    <v-img
      v-if="lightLogo && !forceSoviLogo"
      :src="theme.current.value.dark ? darkLogo : lightLogo"
      contain
      position="left"
      class="cursor-pointer"
      :max-width="130"
      :max-height="100"
    />

    <div v-else :class="titleSize" class="sovi-brand-title">
      <sovi-logo
        :size="logoSize"
        class="cursor-pointer"
        :tertiary="!theme.current.value.dark"
      />
    </div>
  </v-fade-transition>
</template>

<script lang="ts" setup>
import { useAuthorizationStore } from '@/stores/authorization';
import { useContractStore } from '@/stores/contract';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useTheme } from 'vuetify';

const props = withDefaults(
  defineProps<{
    size?: number;
    logoSize?: number;
    hideSubtitleInMobile?: boolean;
    forceSoviLogo?: boolean;
  }>(),
  {
    size: 3,
    logoSize: 42,
    hideSubtitleInMobile: false,
    forceSoviLogo: false,
  },
);

const { logos: contractLogos } = storeToRefs(useContractStore());

const { contractLogos: magicLinkLogos } = storeToRefs(useAuthorizationStore());

const route = useRoute();

const theme = useTheme();

const logos = computed(() =>
  route.name === 'MagicLink' ? magicLinkLogos.value : contractLogos.value,
);

const lightLogo = computed(() => {
  const logo = logos.value?.find((l) => l.scheme === 'light')?.upload_name;

  if (!logo) return '';

  return `https://images.sovi.fi/${logo}`;
});

const darkLogo = computed(() => {
  const logo = logos.value?.find((l) => l.scheme === 'dark')?.upload_name;

  if (!logo) return '';

  return `https://images.sovi.fi/${logo}`;
});

const titleSize = computed(() => {
  const sizes = {
    md: 0,
    sm: props.hideSubtitleInMobile ? 0 : 1,
    xs: props.hideSubtitleInMobile ? 1 : 2,
  };

  return Object.entries(sizes).map(([size, value]) =>
    size === 'xs'
      ? `text-h${props.size + (props.hideSubtitleInMobile ? 0 : value)}`
      : `text-${size}-h${props.size + value}`,
  );
});
</script>

<style lang="scss">
// @use 'mq';
.sovi-brand-title {
  align-items: baseline;
  display: grid;
  grid-template-columns: auto 1fr;

  &__primary,
  &__secondary {
    display: flex;
    align-items: center;
  }

  &__primary {
    margin-right: 8px;
    position: relative;
  }

  &__secondary {
    font-weight: 400;
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "subtitle": "Tehdään sopimus"
  },
  "en": {
    "subtitle": "Reach an agreement"
  }
}
</i18n>
