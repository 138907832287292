<template>
  <v-snackbar
    v-model="showNotification"
    :color="notification.color"
    location="bottom right"
    rounded="xl"
    close-on-content-click
    :timeout="notification.persistent ? -1 : 6000"
  >
    <p v-if="queue.length > 1" class="pa-0 badge -mt-1 mb-2 text-sm">
      <span>
        {{
          t(
            'numberOfNotifications',
            {
              count: queue.length,
            },
            queue.length,
          )
        }}
      </span>
    </p>

    <v-icon class="me-2">{{ notification.icon }}</v-icon>

    <strong class="font-weight-bold">{{ notification.title }}</strong>

    <p v-if="notification.body" class="notification-body pa-0 mb-0 mt-2">
      {{ notification.body }}
    </p>

    <template v-if="notification.persistent" #actions>
      <v-btn
        class="text-none"
        variant="text"
        size="default"
        color=""
        rounded
        @click="onClose()"
      >
        {{ t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useNotificationStore } from '@/stores/notification';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { notification, showNotification, queue } = storeToRefs(
  useNotificationStore(),
);

const { removeNotification } = useNotificationStore();

const onClose = () => {
  showNotification.value = false;
};

const onSnackbarClose = () => {
  removeNotification(notification.value.id);
};

watch(showNotification, (show) => {
  if (show) return;

  onSnackbarClose();
});
</script>

<style lang="scss">
.v-snackbar__wrapper {
  backdrop-filter: blur(4px);
  color: #fff !important;

  &.v-theme--light {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  &.bg-error.v-theme--light {
    color: #fff !important;
  }
}

.badge span {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 2px 6px;
  border-radius: 100vw;
}

.notification-body {
  font-weight: 500;
}
</style>

<i18n lang="json">
{
  "fi": {
    "close": "Sulje",
    "numberOfNotifications": "{count} ilmoitus | {count} ilmoitusta"
  },
  "en": {
    "close": "Close",
    "numberOfNotifications": "{count} notification | {count} notifications"
  }
}
</i18n>
