import { useDocumentStore } from '@/stores/document';
import type { PreloadHandler } from '@/types/router';
import type { RouteLocationNormalized } from 'unplugin-vue-router';

export const preloadDocuments: PreloadHandler = async (
  to: RouteLocationNormalized,
) => {
  const { listDocuments } = useDocumentStore();

  if (!(to.params as { contractId: string }).contractId) {
    console.error('Error in data preloader: missing required route param');

    return;
  }

  await listDocuments((to.params as { contractId: string }).contractId);
};
