import Client from '@/repositories/clients/fetchClient';
import type {
  GetAllTemplatesForContractResponse,
  GetAllTemplatesResponse,
  GetTemplateResponse,
} from '@/types/response';

const resource = 'template';

export const templateRepository = {
  upload: (payload: { name: string; html: string; template: boolean }) => {
    return Client.post<{ success: boolean; id: number; updated_at: string }>(
      resource,
      payload,
    );
  },

  update: (payload: { id: number; html: string }) => {
    return Client.put(resource, payload);
  },

  delete: (payload: { id: number }) => {
    return Client.delete(resource, payload);
  },

  get: (id: number) => Client.get<GetTemplateResponse>(`${resource}/${id}`),

  getAllForContract: (contractId: string) =>
    Client.get<GetAllTemplatesForContractResponse>(
      `${resource}/contract/${contractId}`,
    ),

  getAll: () => Client.get<GetAllTemplatesResponse>(resource),

  attachToContract: (payload: { contractId: string; templateIds: number[] }) =>
    Client.post(`${resource}/attach`, payload),

  removeFromContract: (payload: { contractId: string; templateId: number }) =>
    Client.delete(`${resource}/remove`, payload),
};
