import Client from '@/repositories/clients/fetchClient';
import type { GetEventsResponse } from '@/types/event';

const resource = 'events';

export const eventRepository = {
  getContractEvents: (id: string) =>
    Client.get<GetEventsResponse>(`${resource}/${id}`),

  getAll: (page: number) => Client.get<GetEventsResponse>(`all-events/${page}`),
};
