// const parseDate = (isoString: string) => {
//   const [date, time] = isoString.split('T');
//   const [year, month, day] = date.split('-');
//   const [hours, minutes, seconds] = time.split(':');

//   return new Date(
//     Date.UTC(+year, +month - 1, +day, +hours, +minutes, +seconds),
//   );
// };

type DateType = 'date' | 'month' | 'time' | 'full';

export const formatDate = (
  value: string,
  {
    type = 'date',
    locale = 'fi',
  }: {
    type?: DateType;
    locale?: string | 'fi' | 'en';
  } = {},
) => {
  if (!value || value === '0000-00-00') return '';

  const joiner = value.match(/ \d{1}:/) ? 'T0' : 'T';

  const parsedValue = value.split(' ').join(joiner);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const types: Record<DateType, Intl.DateTimeFormatOptions> = {
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone,
    },
    month: {
      year: 'numeric',
      month: 'long',
      timeZone,
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    },
    full: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    },
  };

  return new Intl.DateTimeFormat(locale, types[type]).format(
    new Date(parsedValue),
  );
};
