import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale:
    localStorage.getItem('sovi-locale') ||
    import.meta.env.VITE_I18N_LOCALE ||
    'en',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    fi: {},
    en: {},
  },
});

export default i18n;
