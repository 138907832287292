import { useContractStore } from '@/stores/contract';
import type { PreloadHandler } from '@/types/router';
import type { RouteLocationNormalized } from 'unplugin-vue-router';

export const preloadContracts: PreloadHandler = async (
  to: RouteLocationNormalized,
) => {
  const { list: getContracts } = useContractStore();

  await getContracts({
    folderId: ((to.params as { folderId: string }).folderId as string) ?? null,
    page: 1,
    limit: 25,
    merge: false,
  });
};
