import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useRequestStore = defineStore('request', () => {
  const requests = ref(new Map());

  const abortRequest = (id: string) => {
    requests.value.get(id).abort();
    removeRequest(id);
  };

  const addRequest = (id: string, controller: AbortController) => {
    requests.value.set(id, controller);
  };

  const removeRequest = (id: string) => {
    requests.value.delete(id);
  };

  return {
    abortRequest,
    addRequest,
    removeRequest,
  };
});
