import { computed, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type {
  AddParticipantPayload,
  DeleteParticipantPayload,
  Participant,
  Role,
} from '@/types/participant';
import { useRoute } from 'vue-router';
import { participantRepository } from '@/repositories';
import { useAuthorizationStore } from './authorization';
import { useLoadingStore } from './loading';

export const useParticipantStore = defineStore('participant', () => {
  const { user } = storeToRefs(useAuthorizationStore());

  const { startLoading, endLoading } = useLoadingStore();

  const route = useRoute();

  const participants = ref<Record<string, Record<number, Participant>>>({}); // participant.{contractId}.{participantId}.participant

  const roles = ref<Role[]>([]);

  const showAddDialog = ref(false);

  const participantId = computed(() => user.value?.id);

  const getParticipant = computed(
    () => (contractId: string, participant_id: number) =>
      participants.value?.[contractId]?.[participant_id] ?? {},
  );

  /**
   * Returns the participants for a specific contract
   *
   * @param {Number} contractId - The contract id
   *
   * @returns {Array} the participants
   */
  const getParticipantsByContractId = computed(
    () => (contractId: string) =>
      participants.value?.[contractId]
        ? Object.values(participants.value[contractId])
        : [],
  );

  const userRole = computed(() => {
    return getParticipantsByContractId
      .value(route.params.contractId as string)
      .find((p) => p.id === participantId.value)?.role;
  });

  const isAuthor = computed(() => userRole.value === 'author');

  /**
   * Creates a new participant for a specific contract
   *
   * @param {Object} payload
   * @param {String} payload.email        - Participant email
   * @param {String} payload.name         - Participant name
   * @param {Number} payload.contractId  - Contract id
   * @param {String} payload.role         - Participant role
   * @param {Number} payload.roleId       - Participant role id
   */
  const createParticipant = async (payload: AddParticipantPayload) => {
    startLoading('createParticipant');

    const {
      data: { user_id: id },
    } = await participantRepository.create(payload);

    participants.value[payload.contractId as string][id] = {
      id,
      ...payload,
    } as Participant;

    endLoading('createParticipant');
  };

  const removeParticipant = async (payload: DeleteParticipantPayload) => {
    startLoading('removeParticipant');

    const { success } = await participantRepository.delete(payload);

    if (success) {
      participants.value[payload.contractId] = Object.fromEntries(
        Object.entries(participants.value[payload.contractId]).filter(
          ([id]) => +id !== payload.participantId,
        ),
      );

      // TODO:  use this if the above is not working
      // delete participants.value[payload.contractId][payload.participantId];
    }

    endLoading('removeParticipant');
  };

  const listParticipants = async (contractId: string) => {
    startLoading('listParticipants');

    const {
      data: { participants: items },
    } = await participantRepository.getAll(contractId);

    participants.value = {
      ...participants.value,
      [contractId]: items.reduce(
        (obj, participant) => {
          obj[participant.id] = participant;

          return obj;
        },
        {} as Record<number, Participant>,
      ),
    };

    endLoading('listParticipants');
  };

  const listRoles = async () => {
    startLoading('listRoles');

    if (roles.value.length) return;

    const { data } = await participantRepository.getRoles();

    roles.value = data.roles;

    endLoading('listRoles');
  };

  const approve = async (payload: { companyId: number }) => {
    startLoading('approve');

    const response = await participantRepository.approve(payload);

    endLoading('approve');

    return response;
  };

  const deny = async (payload: { companyId: number }) => {
    startLoading('deny');

    const response = await participantRepository.deny(payload);

    endLoading('deny');

    return response;
  };

  return {
    participantId,
    participants,
    roles,
    showAddDialog,
    getParticipant,
    getParticipantsByContractId,
    userRole,
    isAuthor,
    createParticipant,
    removeParticipant,
    listParticipants,
    listRoles,
    approve,
    deny,
  };
});
