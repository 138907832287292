<template>
  <sovi-dialog :show="display" :title="t('signedIn')">
    <p>
      {{ t('description') }}
      <br />

      <b>{{ user?.name || user?.email }}</b>
    </p>

    <template #actions>
      <v-spacer />

      <v-btn variant="elevated" rounded @click="emit('close')">
        {{ t('ok') }}
      </v-btn>
    </template>
  </sovi-dialog>
</template>

<script lang="ts" setup>
import { useAuthorizationStore } from '@/stores/authorization';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const { user } = storeToRefs(useAuthorizationStore());

const { t } = useI18n();

defineProps<{
  display: boolean;
}>();

const emit = defineEmits(['close']);
</script>

<!-- eslint-disable -->
<i18n lang="json">
{
  "fi": {
    "description": "Kirjauduit sisään toisella välilehdellä käyttäjällä:",
    "signedIn": "Uusi kirjautuminen",
    "reload": "Lataa uudelleen",
    "ok": "Ok"
  },
  "en": {
    "description": "Signed in on another tab as user:",
    "signedIn": "New login",
    "reload": "Reload page",
    "ok": "Ok"
  }
}
</i18n>
<!-- eslint-enable -->
