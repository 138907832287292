import Client from '@/repositories/clients/fetchClient';
import type {
  GetPaymentHistoryResponse,
  MonthlyCreditsResponse,
} from '@/types/credit';

const resource = 'credit';

export const creditRepository = {
  getCredit: () => Client.get<{ total: number }>(`${resource}`),

  getPaymentHistory: () =>
    Client.get<GetPaymentHistoryResponse>(`${resource}/payments`),

  getMonthlyCredits: (payload: { year: number; month: string }) =>
    Client.get<MonthlyCreditsResponse[]>(
      `${resource}/monthly-credits/${payload.month}/${payload.year}`,
    ),

  getPdfInvoice: (id: number) => Client.get<Blob>(`${resource}/invoice/${id}`),

  grant: (payload: { amount: number; companyId: number }) =>
    Client.post(`${resource}/grant`, payload),

  refundPayment: (id: number) =>
    Client.post<{ success: boolean }>(`${resource}/refund`, { id }),
};
