import type { SocketOptions } from 'node_modules/socket.io-client/build/cjs';
import { io, type ManagerOptions } from 'socket.io-client';
import type { App, Plugin } from 'vue';

export const socketIoInjectionKey = Symbol('socketIoPluginInjectionKey');

export const socketIoPlugin: Plugin = {
  install: (
    app: App,
    {
      connection,
      options,
    }: {
      connection: string;
      options: Partial<ManagerOptions & SocketOptions>;
    },
  ) => {
    const socket = io(connection, options);

    socket.onAny((event) => {
      if (socket.listeners(event).length === 0) {
        console.log(`missing handler for event ${event}`);
      }
    });

    app.config.globalProperties.$socket = socket;

    app.provide(socketIoInjectionKey, socket);
  },
};
