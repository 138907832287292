import { defineStore } from 'pinia';
import { ref } from 'vue';

interface Loader {
  active: boolean;
  message?: string;
}

export const useLoadingStore = defineStore('loading', () => {
  const loaders = ref<Map<string, Loader>>(new Map());

  const showAppLoader = ref(true);

  const startLoading = async (name: string, message?: string) => {
    loaders.value.set(name, { active: true, message });
  };

  const endLoading = async (name: string) => {
    loaders.value.delete(name);
  };

  const isLoading = (name: string | string[]) => {
    return Array.isArray(name)
      ? name.some((item) => loaders.value.has(item))
      : loaders.value.has(name as string);
  };

  const isAnyLoading = () => {
    return loaders.value.size > 0;
  };

  const getLoadingMessage = (name: string) => loaders.value.get(name)?.message;

  return {
    showAppLoader,
    startLoading,
    endLoading,
    isLoading,
    isAnyLoading,
    getLoadingMessage,
  };
});
