<template>
  <div v-if="user?.companies" class="mt-7">
    <sovi-bread-crumbs class="pb-2" />

    <v-card v-for="company in user.companies" :key="company.id" class="mb-6">
      <sovi-company-information :company="company" />
    </v-card>

    <!-- <sovi-company-logo /> -->
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useContractStore } from '@/stores/contract';
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';
import { useAuthorizationStore } from '@/stores/authorization';
import { storeToRefs } from 'pinia';

definePage({
  name: 'CompanySettings',
  meta: {
    hero: true,
    restricted: true,
  },
});

const { user } = storeToRefs(useAuthorizationStore());

const { setBreadCrumbs } = useContractStore();

const { t } = useI18n();

onBeforeMount(async () => {
  const { isAuthorized } = useAuthorizationStore();

  await isAuthorized();

  setBreadCrumbs([
    {
      id: undefined,
      href: '/company-settings',
      name: t('companySettings'),
      disabled: true,
    },
  ]);
});
</script>

<i18n lang="json">
{
  "fi": {
    "companySettings": "Yritysyhteydet"
  },
  "en": {
    "companySettings": "Company connections"
  }
}
</i18n>
