<template>
  <v-snackbar v-model="show" :timeout="-1" color="tertiary">
    <template v-if="lastEvent">
      <div class="flex items-center justify-between">
        <div class="pr-6">
          <p class="ma-0 text-sm font-semibold">
            {{ lastEvent.message[locale as 'fi' | 'en'] }}
          </p>

          <p class="ma-0 text-xs">{{ lastEvent.name }}</p>

          <p class="ma-0 text-xs">
            {{
              formatDate(lastEvent.created_at, {
                locale,
                type: 'full',
              })
            }}
          </p>
        </div>

        <v-btn
          v-if="lastEvent && !alreadyInContract"
          variant="text"
          color=""
          size="default"
          :to="{
            name: 'Contract',
            params: { contractId: lastEvent.hash },
          }"
          @click="onClose()"
        >
          {{ t('open') }}
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useAuthorizationStore } from '@/stores/authorization';
import { useEventStore } from '@/stores/event';
import { formatDate } from '@/utils';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const route = useRoute();

const { t } = useI18n();
const { locale } = useI18n({ useScope: 'global' });

const emit = defineEmits(['close']);

const props = defineProps<{ isLastEventVisible: boolean }>();

watch(
  () => props.isLastEventVisible,
  (value) => {
    if (isLastEventOwnAction.value) return;

    show.value = value;

    setTimeout(() => {
      onClose();
    }, 6000);
  },
);

const onClose = () => emit('close');

const { events } = storeToRefs(useEventStore());

const { user } = storeToRefs(useAuthorizationStore());

const show = ref(false);

const lastEvent = computed(() => {
  return events.value[0];
});

const isLastEventOwnAction = computed(() => {
  const participantId = lastEvent.value?.message?.participantId;

  return participantId && user.value.id && +participantId === +user.value.id;
});

const alreadyInContract = computed(() => {
  return route.params.contractId === lastEvent.value?.hash;
});
</script>

<i18n lang="json">
{
  "fi": {
    "open": "Avaa"
  },
  "en": {
    "open": "Open"
  }
}
</i18n>
