<template>
  <v-dialog
    :content-class="{
      'isolate before:w-[calc(100%_-_96px)] before:h-[calc(100%_-_64px)] after:w-[calc(100%_-_64px)] after:h-[calc(100%_-_48px)] dark:before:bg-tertiary-500 dark:after:bg-tertiary-500 before:bg-primary-400 after:bg-primary-400 before:absolute before:bottom-0 before:right-0 before:translate-x-0.5 before:translate-y-1 before:rounded-xl before:opacity-50 dark:before:opacity-25 after:absolute after:bottom-0 after:right-0 after:-translate-x-1.5 after:-translate-y-1 after:rounded-xl after:opacity-95 dark:after:opacity-50': true,
      'sovi-dialog dark': theme.current.value.dark,
      'sovi-dialog': !theme.current.value.dark,
      'before:!hidden after:!hidden': $attrs.fullscreen,
    }"
    :model-value="show"
    :max-width="maxWidth"
    persistent
    scrollable
    close-on-back
    class="w-"
  >
    <v-form
      ref="formRef"
      v-model="valid"
      :class="{ 'h-screen': $attrs.fullscreen }"
      @submit.prevent="validate()"
    >
      <sovi-card
        :title="title"
        :sticky-actions="stickyActions"
        class="m-2 md:m-3 lg:m-4"
        :class="{ '!m-0 !h-screen': $attrs.fullscreen }"
      >
        <slot />

        <template #actions>
          <slot name="actions" v-bind="{ onClose }" />
        </template>
      </sovi-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useTheme } from 'vuetify';
import type { VForm } from 'vuetify/components';

const theme = useTheme();

withDefaults(
  defineProps<{
    show?: boolean;
    title?: string;
    stickyActions?: boolean;
    maxWidth?: number;
  }>(),
  {
    show: false,
    title: '',
    stickyActions: false,
    maxWidth: 500,
  },
);

const emit = defineEmits<{
  close: [];
  submit: [isValid: boolean];
}>();

const formRef = ref<InstanceType<typeof VForm> | null>(null);

const valid = ref(false);

const onClose = () => {
  formRef.value?.resetValidation();

  emit('close');
};

const onSubmit = (isValid: boolean) => {
  emit('submit', isValid);
};

const reset = () => {
  formRef.value?.resetValidation();
  formRef.value?.reset();
};

const validate = async () => {
  await formRef.value?.validate();

  onSubmit(valid.value);
};

defineExpose({
  reset,
  validate,
});
</script>

<style lang="scss">
.sovi-dialog {
  // overflow: visible;
  &__actions {
    flex-wrap: wrap;
    gap: 8px;

    .v-btn {
      margin: 0 !important;
    }
  }

  .sticky {
    position: sticky;

    &--bottom {
      bottom: 0;
    }
  }
}
</style>
