import Client from '@/repositories/clients/fetchClient';
import type {
  CreatePaymentLinksPayload,
  CreatePaymentLinksResponse,
} from '@/types/checkout';

const resource = 'checkout';

export const checkoutRepository = {
  createPaymentLinks: (payload: CreatePaymentLinksPayload) =>
    Client.post<CreatePaymentLinksResponse>(resource, payload),
};
