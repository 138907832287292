<template>
  <sovi-dialog :show="display" :title="t('loginExpired')">
    <p>
      {{ t('loginExpiredText') }}
    </p>

    <template #actions>
      <v-spacer />

      <v-btn variant="elevated" rounded @click="closeAndRedirect()">
        {{ t('reload') }}
      </v-btn>
    </template>
  </sovi-dialog>
</template>

<script lang="ts" setup>
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const route = useRoute();

const { t } = useI18n();

defineProps<{
  display: boolean;
}>();

const emit = defineEmits(['close']);

const closeAndRedirect = () => {
  emit('close');

  if (route.name !== 'Login') {
    router.push({ name: 'Login' });
  }
};
</script>

<!-- eslint-disable -->
<i18n lang="json">
{
  "fi": {
    "loginExpired": "Istunto vanhentunut",
    "loginExpiredText": "Lataa sivu uudelleen jatkaaksesi",
    "reload": "Lataa uudelleen"
  },
  "en": {
    "loginExpired": "Session expired",
    "loginExpiredText": "Please reload the page to continue",
    "reload": "Reload page"
  }
}
</i18n>
<!-- eslint-enable -->
