import Client from '@/repositories/clients/fetchClient';
import type {
  IsAuthorizedResponse,
  LogoutResponse,
  SessionResponse,
} from '@/types/authorization';

const resource = 'authorization';

export const authorizationRepository = {
  logout: () => Client.get<LogoutResponse>(`${resource}/logout`),
  isAuthorized: () =>
    Client.get<IsAuthorizedResponse>(`${resource}/is-authorized`),
  session: () => Client.get<SessionResponse>(`${resource}/session`),
};
