import type { RouteLocationNormalized } from 'vue-router';
import * as preloaders from './preloaders';

export type DataPreloaders = typeof preloaders;
export type DataPreloader = keyof DataPreloaders;

export const dataPreloader = async (to: RouteLocationNormalized) => {
  const promises = [...(to.meta.preloaders || [])].map((data) =>
    (preloaders as DataPreloaders)[data](to),
  );

  await Promise.all(promises);
};
