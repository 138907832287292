<template>
  <div class="pt-md-3 pt-6">
    <sovi-bread-crumbs class="pb-2" />

    <sovi-card :title="t('billingHistory')">
      <ul v-auto-animate class="space-y-2 overflow-hidden">
        <li
          v-if="isLoading('getPaymentHistory')"
          class="rounded-xl bg-tertiary-500/10 p-4 dark:bg-tertiary-500/5"
        >
          <v-progress-linear indeterminate rounded />
          {{ t('loading') }}...
        </li>

        <li
          v-else-if="!paymentHistory.length"
          class="flex items-center gap-1 rounded-xl bg-tertiary-500/10 p-4 dark:bg-tertiary-500/5"
        >
          <v-avatar>
            <v-icon>mdi-alert-circle</v-icon>
          </v-avatar>
          {{ t('noReceipts') }}
        </li>

        <li
          v-for="item in paymentHistory"
          :key="item.id"
          class="flex items-center gap-2 rounded-xl bg-tertiary-500/10 p-4 dark:bg-tertiary-500/5"
        >
          <div class="grid flex-1 content-center gap-1 leading-none">
            <p class="text-base font-semibold leading-none">
              {{ `Sovi ${item.units}` }}
            </p>

            <p>
              {{
                formatDate(item.payment_date, {
                  locale: $i18n.locale,
                  type: 'full',
                })
              }}
            </p>

            <p>
              {{ `${formatCurrency(item.price)} ${t('incVat')} ${item.vat} %` }}
            </p>
          </div>

          <div class="flex gap-2">
            <v-btn
              v-if="item.status === 'refunded'"
              color=""
              prepend-icon="mdi-keyboard-return"
              size="small"
              disabled
            >
              {{ t('refunded') }}
            </v-btn>

            <v-btn
              v-if="item.refundable"
              color="primary"
              prepend-icon="mdi-keyboard-return"
              size="small"
              @keyup.enter="onShowRefundPaymentDialog(item.id)"
              @click="onShowRefundPaymentDialog(item.id)"
            >
              {{ t('refund') }}
            </v-btn>

            <v-btn
              :loading="isLoading('getPdfInvoice') && clickedId === item.id"
              prepend-icon="mdi-download"
              :color="item.status === 'refunded' ? '' : 'tertiary'"
              size="small"
              :disabled="item.status === 'refunded'"
              @keyup.enter="downloadFile(item.id)"
              @click="downloadFile(item.id)"
            >
              {{ t('receipt') }}
            </v-btn>
          </div>
        </li>
      </ul>
    </sovi-card>

    <sovi-refund-payment-dialog
      :show="showRefundDialog"
      :payment-id="paymentId"
      @close="onCloseRefundPaymentDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { useContractStore } from '@/stores/contract';
import { useCreditStore } from '@/stores/credit';
import { useLoadingStore } from '@/stores/loading';
import { formatCurrency, formatDate } from '@/utils';
import download from 'downloadjs';
import { storeToRefs } from 'pinia';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'BillingHistory',
  meta: {
    preloaders: ['preloadPaymentHistory'],
    hero: true,
    restricted: true,
  },
});

const paymentId = ref<number | null>(null);
const showRefundDialog = ref(false);

const { getPdfInvoice } = useCreditStore();
const { paymentHistory } = storeToRefs(useCreditStore());

const { setBreadCrumbs } = useContractStore();

const { isLoading } = useLoadingStore();

const { t } = useI18n();

const clickedId = ref<number | null>(null);

const downloadFile = async (id: number) => {
  clickedId.value = id;

  const response = await getPdfInvoice(id);

  if (response?.success) {
    download(response.data, `[RECEIPT-SOVI.FI]-${id}.pdf`, 'application/pdf');
  }
};

const onShowRefundPaymentDialog = async (id: number) => {
  paymentId.value = id;

  showRefundDialog.value = true;
};

const onCloseRefundPaymentDialog = async () => {
  paymentId.value = null;

  showRefundDialog.value = false;
};

onBeforeMount(() => {
  setBreadCrumbs([
    {
      id: undefined,
      href: '/billing-history',
      name: t('billingHistory'),
      disabled: true,
    },
  ]);
});
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "loading": "Ladataan",
    "noReceipts": "Ei maksukuitteja",
    "billingHistory": "Laskutushistoria",
    "incVat": "sis. ALV",
    "refund": "Pyydä palautusta",
    "receipt": "Kuitti",
    "refunded": "Palautettu"
  },
  "en": {
    "loading": "Loading",
    "noReceipts": "No payment receipts",
    "billingHistory": "Billing history",
    "incVat": "inc. VAT",
    "refund": "Request refund",
    "receipt": "Receipt",
    "refunded": "Refunded"
  }
}
</i18n>
