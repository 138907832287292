<template>
  <div
    class="fixed inset-0 z-50 grid place-content-center bg-gradient-light dark:bg-gradient-dark"
  >
    <div class="w-screen max-w-96 p-4">
      <sovi-brand-title force-sovi-logo :logo-size="48" class="mb-8" />

      <v-progress-linear
        class="mt-6"
        :color="theme === 'dark' ? 'primary' : 'tertiary'"
        :bg-color="
          theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.5)'
        "
        bg-opacity="1"
        indeterminate
      />

      <p class="text-right text-sm">
        {{ message ? t(message) : t('loading') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';

const { t } = useI18n();

defineProps<{
  message?: string;
}>();

const vuetifyTheme = useTheme();

const theme = ref(
  localStorage.getItem('sovi-theme') ||
    (window.matchMedia('(prefers-color-scheme: dark)')?.matches
      ? 'dark'
      : 'light'),
);

watch(
  theme,
  (value) => {
    vuetifyTheme.global.name.value = value;
    localStorage.setItem('sovi-theme', value);
  },
  { immediate: true },
);
</script>

<i18n lang="json">
{
  "fi": {
    "loading": "Odota hetki...",
    "authenticating": "Haetaan tunnistautumistietoja..."
  },
  "en": {
    "loading": "Please wait...",
    "authenticating": "Loading identification details..."
  }
}
</i18n>
