import Client from '@/repositories/clients/fetchClient';
import type {
  CompanyChange,
  CreateCompanyChangeRequestPayload,
} from '@/types/company';
import type {
  AddParticipantPayload,
  AddParticipantResponse,
  AddUserResponse,
  DeleteParticipantPayload,
  GetApplicantsResponse,
  GetParticipantsResponse,
  GetRolesResponse,
  SearchParams,
  UserItem,
} from '@/types/participant';

import type { User } from '@/types/authorization';

const resource = 'participant';

export const participantRepository = {
  approve: (payload: { companyId: number }) =>
    Client.post<{ success: boolean }>(
      `${resource}/applicants/approve`,
      payload,
    ),

  create: (payload: AddParticipantPayload) =>
    Client.post<AddParticipantResponse>(resource, payload),

  addUser: (payload: User) =>
    Client.post<AddUserResponse>(`${resource}/add-user`, payload),

  delete: (payload: DeleteParticipantPayload) =>
    Client.delete<{ success: boolean }>(`${resource}/contract`, payload),

  deny: (payload: { companyId: number }) =>
    Client.post<{ success: boolean }>(`${resource}/applicants/deny`, payload),

  getAll: (contractId: string) =>
    Client.get<GetParticipantsResponse>(`${resource}s/${contractId}`),

  getApplicants: () =>
    Client.get<GetApplicantsResponse>(`${resource}/applicants`),

  getOne: (participantId: number) => Client.get(`${resource}/${participantId}`),

  getUsers: (page: number, search: SearchParams) =>
    Client.get<{ users: UserItem[] }>(
      `${resource}/users/${page}?consumers=${search.consumers}&companies=${search.companies}&text=${search.text}`,
    ),

  getRoles: () => Client.get<GetRolesResponse>(`${resource}-roles`),

  getCompanyChangeRequests: () =>
    Client.get<{ requests: CompanyChange[] }>(`${resource}/company-change`),

  checkCompanyChangeRequest: () =>
    Client.get<{ exists: boolean }>(`${resource}/company-change/check`),

  createCompanyChangeRequest: (payload: CreateCompanyChangeRequestPayload) =>
    Client.post(`${resource}/company-change`, payload),

  approveChangeRequest: (payload: { changeRequestId: string }) =>
    Client.post(`${resource}/company-change/approve`, payload),

  denyChangeRequest: (payload: { changeRequestId: string }) =>
    Client.post(`${resource}/company-change/deny`, payload),
};
