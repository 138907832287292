type FormatCurrencyOptions = {
  currencyCode?: string;
  currencyDisplay?: 'symbol' | 'code' | 'name';
  digits?: number;
  locale?: string;
  style?: 'currency' | 'decimal';
};

export const formatCurrency = (
  value: number,
  options: FormatCurrencyOptions = {},
) => {
  if (!value && value !== 0) return '';

  const {
    currencyCode = 'EUR',
    currencyDisplay = 'symbol',
    digits = 2,
    locale = 'fi-FI',
    style = 'currency',
  } = options;

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style,
    currency: currencyCode,
    currencyDisplay,
  }).format(value / 100);
};
