<template>
  <div
    :style="`--delay: ${index * 200}ms;`"
    class="grid max-w-96 grow gap-4 rounded-xl border p-6 dark:border-zinc-700 dark:bg-zinc-800"
  >
    <div class="flex gap-2">
      <v-icon
        v-if="item.signatures < 10"
        :color="item.selected ? 'primary' : 'tertiary'"
      >
        mdi-account
      </v-icon>

      <v-icon
        v-else-if="item.signatures < 100"
        :color="item.selected ? 'primary' : 'tertiary'"
      >
        mdi-account-multiple
      </v-icon>

      <v-icon v-else :color="item.selected ? 'primary' : 'tertiary'">
        mdi-account-group
      </v-icon>

      SOVI {{ item.signatures }}
    </div>

    <div>
      <div class="text-2xl md:text-3xl">{{ formatCurrency(item.price) }}</div>

      <span class="text-sm">{{ t('incVat') }}</span>
    </div>

    <p v-html="t('includes', { amount: item.signatures }, item.signatures)" />

    {{ unitPrice }}

    <v-hover>
      <template #default="{ isHovering, props: hoverProps }">
        <v-btn
          :outlined="!item.selected"
          class="text-none sovi-package-item__action"
          :color="item.selected ? 'primary' : 'tertiary'"
          :variant="item.selected ? 'elevated' : 'outlined'"
          :class="{ 'sovi-package-item__action--active': item.selected }"
          v-bind="hoverProps"
          block
          @click="onClick"
        >
          <span class="sovi-package-item__action-content">
            <v-slide-x-reverse-transition>
              <v-icon
                v-if="isHovering || item.selected"
                class="sovi-package-item__icon"
                left
              >
                mdi-check
              </v-icon>
            </v-slide-x-reverse-transition>
            {{ buttonText }}
          </span>
        </v-btn>
      </template>
    </v-hover>
  </div>

  <v-col
    :style="`--delay: ${index * 200}ms;`"
    :class="{ 'sovi-package--active': item.selected }"
    class="sovi-package hidden"
    cols="12"
    md="6"
    lg="4"
  >
    <v-card
      class="sovi-package-item flat"
      :class="{ 'sovi-package-item--active': item.selected }"
      :color="theme.current.value.dark ? 'background' : 'white'"
      outlined
    >
      <v-card-title class="justify-center">
        {{ t('package') }}
        <span class="ml-2">{{ item.signatures }}</span>
      </v-card-title>

      <v-card-text>
        <p class="text-h3 sovi-package-item__price">{{ price }}</p>

        <p
          v-html="t('includes', { amount: item.signatures }, item.signatures)"
        />

        <v-chip outlined>
          {{ unitPrice }}
        </v-chip>
      </v-card-text>

      <v-card-actions class="sovi-package-item__actions">
        <v-hover>
          <template #default="{ isHovering, props: hoverProps }">
            <v-btn
              :outlined="!item.selected"
              class="text-none sovi-package-item__action"
              :color="item.selected ? 'quaternary' : 'tertiary'"
              :class="{ 'sovi-package-item__action--active': item.selected }"
              v-bind="hoverProps"
              block
              depressed
              large
              rounded
              @click="onClick"
            >
              <span class="sovi-package-item__action-content">
                <v-slide-x-reverse-transition>
                  <v-icon
                    v-if="isHovering || item.selected"
                    class="sovi-package-item__icon"
                    left
                  >
                    mdi-check
                  </v-icon>
                </v-slide-x-reverse-transition>
                {{ buttonText }}
              </span>
            </v-btn>
          </template>
        </v-hover>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { formatCurrency } from '@/utils';
import { useTheme } from 'vuetify';

const theme = useTheme();

const props = withDefaults(
  defineProps<{
    index?: number;
    item?: any;
  }>(),
  {
    index: 0,
    item: {},
  },
);

const { t } = useI18n();

const emit = defineEmits<{
  select: [id: number];
}>();

const buttonText = computed(() =>
  t(props.item.selected ? 'selected' : 'select'),
);

const price = computed(() => {
  return formatCurrency(props.item.price);
});

const unitPrice = computed(() => {
  return `${formatCurrency(props.item.price / props.item.signatures)} / ${t(
    'pcs',
  )}`;
});

const onClick = () => {
  emit('select', props.item.id);
};
</script>

<style lang="scss">
// @use 'mq';

.sovi-package {
  --color-after: rgb(var(--v-theme-tertiary));
  --color-before: rgb(var(--v-theme-tertiary));

  animation-duration: 800ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: var(--delay, 0);

  &--active {
    --color-after: var(--v-quaternary-base);
    --color-before: var(--v-quaternary-base);
  }

  &-item {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    overflow: hidden;
    position: relative;

    // @include mq($until: md) {
    //   max-width: 560px;
    //   justify-self: center;
    // }

    &::before {
      background: linear-gradient(var(--color-before), var(--color-after));
      border-radius: 8px;
      content: '';
      position: absolute;
      top: 16px;
      left: 0;
      transform: translateX(-8px);
      width: 8px;
      height: calc(100% - 32px);
      transition: transform 0.6s cubic-bezier(0, 0, 0.2, 1);
    }

    &--active {
      &::before {
        transform: translateX(-4px);
      }
    }

    &__action {
      &--active {
        pointer-events: none;
      }

      &-content {
        position: relative;
      }
    }

    &__icon {
      position: absolute !important;
      left: -28px;
      top: 1px;
    }

    &__price {
      background-color: rgb(var(--v-theme-primary));
      background-image: linear-gradient(
        var(--color-before),
        var(--color-after)
      );
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }

    .v-card__actions {
      width: 100%;
    }

    &.v-card {
      border: thin solid rgba(0, 0, 0, 0.07) !important;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-16px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "includes": "Sisältää <b>{amount}</b> allekirjoituskrediitin | Sisältää <b>{amount}</b> allekirjoituskrediittiä ",
    "pcs": "kpl",
    "incVat": "sis. ALV",
    "package": "Sovi",
    "select": "Valitse",
    "selected": "Valittu"
  },
  "en": {
    "includes": "Includes <b>{amount}</b> signature credit | Includes <b>{amount}</b> signature credits",
    "pcs": "pc",
    "incVat": "inc. VAT",
    "package": "Sovi",
    "select": "Select",
    "selected": "Selected"
  }
}
</i18n>
