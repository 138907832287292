<template>
  <p
    ref="logoRef"
    class="sovi-logo mb-1"
    :class="{
      'sovi-logo--dark': theme.current.value.dark || dark,
      'sovi-logo--tertiary': tertiary,
    }"
  >
    SOVI
  </p>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useTheme } from 'vuetify';

const theme = useTheme();

const logoRef = ref<HTMLParagraphElement | null>(null);

const props = withDefaults(
  defineProps<{
    size?: number;
    dark?: boolean;
    tertiary?: boolean;
  }>(),
  {
    size: 32,
    dark: false,
    tertiary: false,
  },
);

onMounted(() => {
  logoRef.value?.style.setProperty(
    '--sovi-logo-size',
    `clamp(${props.size * 0.75}px, 5vw, ${props.size}px)`,
  );
});
</script>

<style lang="scss">
.sovi-logo {
  --sovi-logo-color: rgba(0, 0, 0, 0.64);
  --sovi-logo-underline-color: var(--color-primary-500);

  font-size: var(--sovi-logo-size);
  line-height: 1;
  font-weight: 700;
  color: var(--sovi-logo-color);
  position: relative;
  isolation: isolate;

  &::after {
    content: '';
    position: absolute;
    bottom: calc(-1 * clamp(4px, 0.5vw, 6px));
    left: 0;
    width: 1ch;
    background-color: var(--sovi-logo-underline-color);
    border-radius: 6px;
    height: clamp(4px, 0.5vw, 6px);
  }

  &--dark {
    --sovi-logo-color: rgba(255, 255, 255, 0.85);
  }

  &--tertiary {
    --sovi-logo-underline-color: var(--color-tertiary-500);
  }
}
</style>
