<template>
  <sovi-dialog
    :show="display"
    :title="t('companyRequest')"
    @close="emit('cancel')"
    @submit="onCompanyRequestSubmit"
  >
    <div v-if="!netsPerson || needsToIdentify" class="grid gap-4">
      <p>{{ t('companyRequestContent') }}</p>

      <p>{{ t('needToIdentify') }}</p>
    </div>

    <div v-else class="grid gap-4">
      <p>{{ t('companyRequestContent') }}</p>

      <v-form
        ref="formRef"
        v-model="valid"
        class="grid gap-4"
        @submit.prevent="onCompanyRequestSubmit()"
      >
        <v-checkbox
          v-model="consent"
          :label="t('checkboxWarning')"
          class="w-full md:w-auto"
          color="tertiary"
          hide-details
        />
      </v-form>
    </div>

    <template #actions="{ onClose }">
      <v-spacer />

      <v-btn variant="text" color="" rounded @click="onClose">
        {{ t('cancel') }}
      </v-btn>

      <v-btn
        v-if="!netsPerson || needsToIdentify"
        variant="elevated"
        color="primary"
        rounded
        @click="onIdentify"
      >
        {{ t('identify') }}
      </v-btn>

      <v-btn
        v-else
        :disabled="submitting || !consent"
        :loading="submitting"
        color="primary"
        type="submit"
        variant="elevated"
        rounded
      >
        {{ t('ok') }}
      </v-btn>
    </template>
  </sovi-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
// import { accessRepository } from '@/repositories';
import { useNotificationStore } from '@/stores/notification';
import type { VForm } from 'vuetify/components';
import { virreRepository } from '@/repositories/virreRepository';
import { useNetsStore } from '@/stores/nets';
import { useAuthorizationStore } from '@/stores/authorization';
import { storeToRefs } from 'pinia';

withDefaults(
  defineProps<{
    display?: boolean;
  }>(),
  { display: false },
);

const { t } = useI18n();

const { addNotification } = useNotificationStore();

const emit = defineEmits(['submit', 'cancel']);

const valid = ref(false);

const needsToIdentify = ref(false);

const consent = ref(false);

const submitting = ref(false);

const formRef = ref<InstanceType<typeof VForm> | null>(null);

const { netsPerson } = storeToRefs(useAuthorizationStore());

const onCompanyRequestSubmit = async () => {
  formRef.value?.validate();

  if (!valid.value) return;

  submitting.value = true;

  const { data } = await virreRepository.setCompanyConnections();

  submitting.value = false;

  if (!data.success && data.error !== 'noNetsPerson') {
    addNotification({
      title: t('requestError'),
      body: t((data.error as string) || 'requestErrorBody'),
      persistent: true,
      icon: 'mdi mdi-alert',
      color: 'error',
    });

    return;
  }

  // TODO:  Handle noNetsPerson error

  needsToIdentify.value = !data.success && data.error === 'noNetsPerson';

  if (needsToIdentify.value) return;

  const { isAuthorized } = useAuthorizationStore();
  const { user } = storeToRefs(useAuthorizationStore());

  await isAuthorized();

  if (user.value?.companies.length) {
    addNotification({
      title: t('requestSuccess'),
      icon: 'mdi mdi-check-circle',
    });
  } else {
    addNotification({
      title: t('requestSuccessNoCompanies'),
      icon: 'mdi mdi-alert-circle',
      persistent: true,
      color: 'error',
    });
  }

  emit('cancel');

  formRef.value?.reset();
};

const { getLink } = useNetsStore();

const onIdentify = async () => {
  const response = await getLink({
    contractId: 'none?action=businessFeatures',
  });

  window.location.href = response.link;
};
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "companyName": "Yrityksen nimi",
    "businessId": "Y-tunnus",
    "phone": "Puhelinnumero",
    "cancel": "Peruuta",
    "ok": "Ota käyttöön",
    "requiredValue": "Vaadittu tieto",
    "invalidValue": "Virheellinen arvo",
    "companyRequest": "Aktivoi yritystili",
    "requestError": "Toiminto epäonnistui",
    "requestErrorBody": "Yritä myöhemmin uudelleen",
    "requestSuccess": "Yritysyhteydet onnistuneesti lisätty tiliisi",
    "requestSuccessNoCompanies": "Patentti- ja rekisterihallitus ei palauttanut henkilötunnukseesi sidottuja yritysyhteyksiä",
    "companyRequestContent": "Haluatko personoida palvelun yrityksesi logolla ja allekirjoittaa sopimuksia yrityksen edustajana? Toiminnon avulla saat Patentti- ja rekisterihallituksen tiedossa olevat yritysyhteytesi liitettyä tiliisi. Toiminnon voi suorittaa vain kerran käyttäjätunnusta kohden.",
    "checkboxWarning": "Ymmärrän, että minulla pitää olla yritysyhteyksiä, jotta voin suorittaa tämän toiminnon. Väärinkäytökset voivat johtaa tunnukseni sulkemiseen.",
    "recentlyChecked": "Olet pyytänyt yritystoimintoja viimeisen 7 vuorokauden sisällä.",
    "needToIdentify": "Tunnistaudu ottaaksesi yritystoiminnot käyttöön.",
    "identify": "Tunnistaudu"
  },
  "en": {
    "companyName": "Company name",
    "businessId": "Business ID",
    "phone": "Phone number",
    "cancel": "Cancel",
    "ok": "Apply",
    "requiredValue": "Required field",
    "invalidValue": "Invalid value",
    "companyRequest": "Activate business account",
    "requestError": "Operation failed",
    "requestErrorBody": "Try again later",
    "requestSuccess": "Business connections successfully added to your account",
    "requestSuccessNoCompanies": "Finnish Patent and Registration Office did not provide company connections related to your social security number",
    "companyRequestContent": "Do you want to personalize the service with your company's logo and sign contracts on behalf of a company? This feature will link your business connections from the Finnish Patent and Registration Office to your account. This feature can be used only once per user account.",
    "checkboxWarning": "I understand that I must have business connections in order to perform this function. Abuses may lead to the termination of my account.",
    "recentlyChecked": "You have requested business features within the last 7 days.",
    "needToIdentify": "Identify yourself to activate the business account",
    "identify": "Identify"
  }
}
</i18n>
