import Client from '@/repositories/clients/fetchClient';

const resource = 'virre';

export const virreRepository = {
  setCompanyConnections: () =>
    Client.post<{ success: boolean; error?: string }>(resource, {}),
  checkRights: (id: string) =>
    Client.post<{ success: boolean; message?: string }>(
      `${resource}/check/${id}`,
      {},
    ),
};
