import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { creditRepository } from '@/repositories';
import type { Payment } from '@/types/credit';
import { useLoadingStore } from './loading';

export const useCreditStore = defineStore('credit', () => {
  const { startLoading, endLoading } = useLoadingStore();

  const total = ref<number | null>(null);

  type CreditRow = { total: string | number; day: number };

  const history = ref<Payment[]>([]);

  const monthlyCredits = ref<CreditRow[]>();

  const getCurrentCredit = async (forceFetch = false) => {
    try {
      startLoading('getCurrentCredit');

      if (total.value === null || forceFetch) {
        const { data, error, success } = await creditRepository.getCredit();

        if (!success) throw error;

        total.value = data.total;
      }

      endLoading('getCurrentCredit');
    } catch (error) {
      console.error(error);
      endLoading('getCurrentCredit');
    }
  };

  const isRefundable = (item: Payment) => {
    const dateObj = new Date(item.payment_date);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - dateObj.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    console.log('diffDays', total.value, item.units, diffDays);

    return (
      diffDays <= 14 &&
      (total.value || 0) >= item.units &&
      item.status === 'paid'
    );
  };

  const getPaymentHistory = async () => {
    try {
      startLoading('getPaymentHistory');

      const { data, success, error } =
        await creditRepository.getPaymentHistory();

      if (!success) throw error;

      history.value = data.payments.reverse();

      endLoading('getPaymentHistory');
    } catch (error) {
      endLoading('getPaymentHistory');
      console.error(error);
    }
  };

  const paymentHistory = computed<Payment[]>(() =>
    history.value.map((item) => ({
      ...item,
      refundable: isRefundable(item),
    })),
  );

  const getPdfInvoice = async (id: number) => {
    try {
      startLoading('getPdfInvoice');
      const response = await creditRepository.getPdfInvoice(id);

      endLoading('getPdfInvoice');

      return response;
    } catch (error) {
      console.error(error);

      endLoading('getPdfInvoice');

      return null;
    }
  };

  const daysInMonth = (year: number, month: number) =>
    new Date(year, month, 0).getDate();

  const getMonthlyCredits = async (payload: {
    year: number;
    month: string;
  }) => {
    startLoading('getMonthlyCredits');

    const { data } = await creditRepository.getMonthlyCredits(payload);

    const dayCount = daysInMonth(payload.year, +payload.month);

    const days = Array.from(
      { length: dayCount },
      (item, index) => index + 1,
    ).map((day) => ({
      day,
      total: data.find((i) => +i.day === day)?.total || 0,
    }));

    monthlyCredits.value = days;

    endLoading('getMonthlyCredits');
  };

  const grantCredits = async (payload: {
    amount: number;
    companyId: number;
  }) => {
    startLoading('grantCredits');

    await creditRepository.grant(payload);

    endLoading('grantCredits');
  };

  const refundPayment = async (id: number) => {
    startLoading('refundPayment');

    const response = await creditRepository.refundPayment(id);

    await getPaymentHistory();

    endLoading('refundPayment');

    return response.data;
  };

  return {
    total,
    paymentHistory,
    monthlyCredits,
    getCurrentCredit,
    getPaymentHistory,
    getPdfInvoice,
    grantCredits,
    getMonthlyCredits,
    refundPayment,
  };
});
