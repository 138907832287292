<template>
  <sovi-dialog
    :show="model"
    :title="t('packages')"
    :max-width="960"
    sticky-actions
    @close="onCloseDialog()"
    @submit="onPurchase"
  >
    <p v-if="showCreditMessage" class="mb-10">
      {{ t('creditsLeft', { credit }, credit ?? 0) }}
      <template v-if="creditsNeeded > 0">
        {{ t('creditsNeeded', { creditsNeeded }, creditsNeeded) }}
      </template>
      {{ t('pleasePurhaseMoreCredits') }}
    </p>

    <div
      class="sovi-packages__items mb-2 flex flex-wrap justify-center gap-6"
      justify="center"
    >
      <sovi-package
        v-for="(item, index) in packages"
        :key="item.id"
        :item="item"
        :index="index"
        @select="onSelection(item)"
      />
    </div>

    <p class="mt-6 text-center text-sm">
      <a
        target="_blank"
        rel="noopener noreferrer"
        class="font-semibold text-tertiary-500 decoration-white underline-offset-4 hover:underline"
        href="/pdf/Sovi_toimitus_ja_maksuehdot.pdf"
        @click.stop
      >
        {{ t('paymentTerms') }}
      </a>
    </p>

    <template #actions="{ onClose }">
      <v-spacer v-if="!isMobile" />

      <v-btn
        variant="text"
        :block="isMobile"
        color=""
        @click="onCloseDialog(onClose)"
      >
        {{ t('cancel') }}
      </v-btn>

      <v-btn
        :disabled="isLoading('createPaymentLinks') || !selectedPackage.id"
        :loading="isLoading('createPaymentLinks')"
        :block="isMobile"
        variant="elevated"
        type="submit"
      >
        {{ t('purchase') }}

        <template #append>
          <v-chip v-if="selectedPackage.id" class="relative -right-1" rounded>
            {{ formatCurrency(selectedPackage.price) }}
          </v-chip>
        </template>
      </v-btn>
    </template>

    <sovi-payment
      :providers="paymentData.providers"
      :show="showPaymentDialog"
      @close="showPaymentDialog = false"
    />
  </sovi-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { storeToRefs } from 'pinia';
import { formatCurrency } from '@/utils';
import { useCreditStore } from '@/stores/credit';
import { usePackageStore } from '@/stores/package';
import { useLoadingStore } from '@/stores/loading';
import type { Package } from '@/types/package';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const model = defineModel<boolean>({ default: false });

const { isLoading } = useLoadingStore();

const { t } = useI18n();

const { total: credit } = storeToRefs(useCreditStore());

const { creditsNeeded, packages, paymentData, showCreditMessage } =
  storeToRefs(usePackageStore());

const { createPaymentLinks, setActivePackage } = usePackageStore();

const display = useDisplay();

const route = useRoute();

const selectedPackage = ref<Package>({} as Package);
const showPaymentDialog = ref(false);

const isMobile = computed(() => display.mobile.value);

const onPurchase = async () => {
  await createPaymentLinks({
    callback: route.path,
    packageId: selectedPackage.value.id,
  });

  showPaymentDialog.value = true;
};

const onSelection = (item: Package) => {
  selectedPackage.value = item;

  setActivePackage(item.id);
};

const onCloseDialog = (closeFn?: () => void) => {
  selectedPackage.value = {} as Package;

  model.value = false;

  if (closeFn) closeFn();
};
</script>

<style lang="scss">
.sticky {
  position: sticky;

  &--bottom {
    bottom: 0;
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "cancel": "Peruuta",
    "creditsLeft": "Sinulla ei ole allekirjoituskrediittejä jäljellä. | Sinulla on vain {credit} allekirjoituskrediitti jäljellä. | Sinulla on vain {credit} allekirjoituskrediittiä jäljellä.",
    "creditsNeeded": "Toimintoon tarvitaan vähintään {creditsNeeded} allekirjoituskrediitti. | Toimintoon tarvitaan vähintään {creditsNeeded} allekirjoituskrediittiä.",
    "packages": "Allekirjoituspaketit",
    "pleasePurhaseMoreCredits": "Ole hyvä ja osta allekirjoituspaketti ja yritä uudelleen.",
    "purchase": "Osta",
    "paymentTerms": "Toimitus- ja maksuehdot"
  },
  "en": {
    "cancel": "Cancel",
    "creditsLeft": "You have no signature credits left. | You have only {credit} signature credit left. | You have only {credit} signature credits left.",
    "creditsNeeded": "The current action requires at least {creditsNeeded} signature credit. | The current action requires at least {creditsNeeded} signature credits.",
    "packages": "Signature packages",
    "pleasePurhaseMoreCredits": "Please purchase a signature package and try again.",
    "purchase": "Purchase",
    "paymentTerms": "Terms of payment and delivery"
  }
}
</i18n>
