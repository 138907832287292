<template>
  <div class="pt-md-3 pt-6">
    <sovi-bread-crumbs class="mb-4" />

    <div class="flex gap-4" :class="isMobile ? 'flex-wrap' : ''">
      <v-tabs
        v-model="tab"
        :direction="!isMobile ? 'vertical' : 'horizontal'"
        color="tertiary"
      >
        <v-tab class="body-2 justify-start">
          <v-icon start>mdi-chart-box-multiple-outline</v-icon>
          {{ t('statistics') }}
        </v-tab>

        <v-tab class="body-2 justify-start">
          <v-icon start>mdi-account-multiple</v-icon>
          {{ t('users') }}
        </v-tab>
      </v-tabs>

      <v-window v-model="tab" class="w-full">
        <v-window-item value="option-1">
          <sovi-monthly-credits />
        </v-window-item>

        <v-window-item value="option-2">
          <sovi-users />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useContractStore } from '@/stores/contract';
import { ref, onBeforeMount, computed } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'AdminView',
  meta: {
    hero: true,
    restricted: true,
  },
});

const { t } = useI18n();
const { setBreadCrumbs } = useContractStore();

const display = useDisplay();

const isMobile = computed(() => display.mobile.value);

const tab = ref(0);

onBeforeMount(() => {
  setBreadCrumbs([
    {
      id: undefined,
      href: '/admin-view',
      name: 'AdminView',
      disabled: true,
    },
  ]);
});
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "users": "Käyttäjät",
    "companies": "Yritykset",
    "statistics": "Tilastot",
    "adminView": "Ylläpito"
  },
  "en": {
    "users": "Users",
    "companies": "Companies",
    "statistics": "Statistics",
    "adminView": "Admin view"
  }
}
</i18n>
