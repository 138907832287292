import { ref } from 'vue';
import { defineStore } from 'pinia';
import { eventRepository } from '@/repositories';
import type { Event, GetEventsPayload } from '@/types/event';

export const useEventStore = defineStore('event', () => {
  const contractEvents = ref<Event[]>([]);
  const events = ref<Event[]>([]);
  const page = ref(0);
  const showLastEvent = ref(false);

  const getEvents = async (payload: GetEventsPayload) => {
    try {
      if (!payload.forceFetch && events.value.length) return;

      if (payload.paginate) {
        page.value += 1;
      } else {
        page.value = 0;
      }

      const { data, error, success } = await eventRepository.getAll(page.value);

      if (!success) throw error;

      if (payload.paginate) {
        events.value.push(
          ...data.events.map((item) => {
            return { ...item, acknowledged: +item.acknowledged };
          }),
        );

        return;
      }

      events.value = data.events.map((item) => {
        return { ...item, acknowledged: +item.acknowledged };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getContractEvents = async (contractId: string) => {
    try {
      contractEvents.value = [];

      const { data, error, success } =
        await eventRepository.getContractEvents(contractId);

      if (!success) throw error;

      contractEvents.value = data.events;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    contractEvents,
    events,
    page,
    showLastEvent,
    getContractEvents,
    getEvents,
  };
});
