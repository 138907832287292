import { useParticipantStore } from '@/stores/participant';
import type { PreloadHandler } from '@/types/router';
import type { RouteLocationNormalized } from 'unplugin-vue-router';

export const preloadParticipants: PreloadHandler = async (
  to: RouteLocationNormalized,
) => {
  const { listParticipants } = useParticipantStore();

  if (!(to.params as { contractId: string }).contractId) {
    console.error('Error in data preloader: missing required route param');

    return;
  }

  await listParticipants((to.params as { contractId: string }).contractId);
};
