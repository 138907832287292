import { createRouter, createWebHistory } from 'vue-router/auto';
import { routes } from 'vue-router/auto-routes';
import { useContractStore } from '@/stores/contract';
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import { useAuthorizationStore } from '@/stores/authorization';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import type { RouteRecordName } from 'unplugin-vue-router';
import type { IsAuthorizedResponse } from '@/types/authorization';
import { useEventStore } from '@/stores/event';
import { useNotificationStore } from '@/stores/notification';
import { usePackageStore } from '@/stores/package';
import { useCreditStore } from '@/stores/credit';
import { useLoadingStore } from '@/stores/loading';
import { dataPreloader } from './dataPreloader';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const { getCurrentCredit } = useCreditStore();
    const { getPackages } = usePackageStore();
    const { packages } = storeToRefs(usePackageStore());
    const { addNotification } = useNotificationStore();
    const { getEvents } = useEventStore();
    const { getLogos } = useContractStore();
    const { showHero, loading, routeLoader } = storeToRefs(useAppStore());
    const { tokenLogin, isAuthorized } = useAuthorizationStore();
    const { user, authorized } = storeToRefs(useAuthorizationStore());
    const { startLoading, endLoading } = useLoadingStore();

    if (!from.name) {
      startLoading('sovi-fullscreen-loader');
    }

    if (to.name !== 'Contract') {
      await getLogos(null);
    }

    const soviToken = localStorage.getItem('sovi-token');

    if (to.name === 'MagicLink') {
      showHero.value = to.meta?.hero ?? true;

      localStorage.removeItem('sovi-token');

      next();

      return;
    }

    if (
      !!soviToken &&
      !user.value?.id &&
      !['Nets', 'MagicLink'].includes(to.name as RouteRecordName)
    ) {
      await tokenLogin({ token: soviToken });
    }

    await dataPreloader(to);

    if (
      (from.name !== 'Contracts' && to.name === 'Contract') ||
      to.name !== 'Contract'
    ) {
      loading.value = to.name !== from.name;
    }

    routeLoader.value = to.fullPath;

    if (to.name === 'Nets') {
      showHero.value = to.meta?.hero ?? true;

      next();

      return;
    }

    if (to.meta.restricted) {
      if (
        !(
          authorized.value ||
          ((await isAuthorized()) as IsAuthorizedResponse).authorized
        )
      ) {
        showHero.value = to.meta?.hero ?? true;

        next({ name: 'Login' });

        endLoading('sovi-fullscreen-loader');

        return;
      }

      if (to.name !== 'Contract') {
        await getEvents({
          forceFetch: false,
          paginate: false,
        });
      }
    }

    if (to.query?.['checkout-status']) {
      const { 'checkout-status': status, 'checkout-amount': amount } = to.query;

      const locale = localStorage.getItem('sovi-locale') || 'fi';

      if (status === 'ok') {
        await getPackages();
        await getCurrentCredit(true);

        const { signatures: credits = 0 } =
          packages.value?.find((item) => +item.price === +(amount ?? 0)) || {};

        const translations = {
          title: {
            fi: 'Maksu onnistui',
            en: 'Payment successful',
          },
          subtitle: {
            fi:
              credits > 1
                ? `Sinulla on ${credits} uutta allekirjoituskrediittiä`
                : `Sinulla on ${credits} uusi allekirjoituskrediitti`,
            en:
              credits > 1
                ? `You have ${credits} new signature credits`
                : `You have ${credits} new signature credit`,
          },
        };

        addNotification({
          title: translations.title[locale as 'fi' | 'en'],
          body: translations.subtitle[locale as 'fi' | 'en'],
          icon: 'mdi mdi-check-circle',
        });
      }

      if (status === 'fail') {
        const translations = {
          title: {
            fi: 'Maksu epäonnistui',
            en: 'Payment failed',
          },
          subtitle: {
            fi: 'Yritä uudelleen',
            en: 'Please try again',
          },
        };

        addNotification({
          title: translations.title[locale as 'fi' | 'en'],
          body: translations.subtitle[locale as 'fi' | 'en'],
          persistent: true,
          icon: 'mdi mdi-alert',
          color: 'error',
        });
      }

      to.query = {};

      endLoading('sovi-fullscreen-loader');

      showHero.value = to.meta?.hero ?? true;

      next(to);

      return;
    }

    showHero.value = to.meta?.hero ?? true;

    endLoading('sovi-fullscreen-loader');

    next();
  },
);

router.afterEach(() => {
  const { loading, routeLoader } = storeToRefs(useAppStore());

  loading.value = false;
  routeLoader.value = '';
});

export default router;
