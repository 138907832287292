import { ref } from 'vue';
import { defineStore } from 'pinia';
import { checkoutRepository, packageRepository } from '@/repositories';
import type { Package } from '@/types/package';
import type { CreatePaymentLinksPayload, Links } from '@/types/checkout';
import { useLoadingStore } from './loading';

export const usePackageStore = defineStore('package', () => {
  const creditsNeeded = ref(0);
  const openContractDialog = ref(false);
  const packages = ref<Package[]>([]); // items in vuex store
  const vat = ref(25.5);
  const paymentData = ref<Links>({} as Links);
  const showCreditMessage = ref(false);
  const showDialog = ref(false);

  const { startLoading, endLoading } = useLoadingStore();

  const createPaymentLinks = async (payload: CreatePaymentLinksPayload) => {
    startLoading('createPaymentLinks');

    const {
      success,
      error,
      data: { links },
    } = await checkoutRepository.createPaymentLinks(payload);

    if (!success) {
      endLoading('createPaymentLinks');

      throw error;
    }

    paymentData.value = links;

    endLoading('createPaymentLinks');
  };

  const getPackages = async () => {
    try {
      if (packages.value.length) return;

      startLoading('getPackages');

      const { data, error, success } = await packageRepository.getAll();

      if (!success) throw error;

      packages.value = data.packages;

      vat.value = data.vat;

      endLoading('getPackages');
    } catch (error) {
      console.error(error);
      endLoading('getPackages');
    }
  };

  const setActivePackage = (id: number) => {
    packages.value = packages.value.map((item) => ({
      ...item,
      selected: item.id === id,
    }));
  };

  const showPackageDialog = (payload?: {
    showCreditMessage?: boolean;
    creditsNeeded?: number;
  }) => {
    const options = {
      creditsNeeded: payload?.creditsNeeded || 0,
      showCreditMessage: payload?.showCreditMessage || false,
    };

    showDialog.value = true;
    showCreditMessage.value = options.showCreditMessage;
    creditsNeeded.value = options.creditsNeeded;
  };

  const hidePackageDialog = () => {
    showDialog.value = true;
    showCreditMessage.value = false;
    creditsNeeded.value = 0;
  };

  return {
    creditsNeeded,
    packages,
    openContractDialog,
    paymentData,
    showCreditMessage,
    showDialog,
    vat,
    showPackageDialog,
    hidePackageDialog,
    createPaymentLinks,
    getPackages,
    setActivePackage,
  };
});
