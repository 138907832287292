<template>
  <div class="grid h-full place-content-center">
    <p class="text-center text-9xl font-semibold">404</p>

    <p class="text-center text-3xl font-semibold text-tertiary-600">
      {{ t('pageNotFound') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';

definePage({
  name: '404',
  meta: {
    hideHeaderActions: true,
    restricted: false,
  },
});

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "fi": {
    "pageNotFound": "Sivua ei löytynyt"
  },
  "en": {
    "pageNotFound": "Page not found"
  }
}
</i18n>
