import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type { ContractTemplate } from '@/types/template';
import { templateRepository } from '@/repositories';
import { useLoadingStore } from './loading';
import { useDocumentStore } from './document';
import { useContractStore } from './contract';

export const useTemplateStore = defineStore('template', () => {
  const { startLoading, endLoading } = useLoadingStore();

  const { listDocuments } = useDocumentStore();

  const { contractTemplates } = storeToRefs(useContractStore());

  const templates = ref<ContractTemplate[]>([]);

  const getAllTemplates = async () => {
    startLoading('getAllTemplates');

    const { data, error, success } = await templateRepository.getAll();

    if (error) {
      console.error(error);
    }

    templates.value = success ? data.data : [];

    endLoading('getAllTemplates');
  };

  const getTemplate = async (id: number, preview = false) => {
    const loader = `getTemplate--${id}${preview ? '-preview' : ''}`;

    startLoading(loader);

    const response = await templateRepository.get(id);

    const res = await fetch(response.data.data, { method: 'GET' });

    const html = await res.text();

    endLoading(loader);

    return html;
  };

  const uploadTemplate = async (payload: {
    name: string;
    html: string;
    template: boolean;
  }) => {
    startLoading('uploadTemplate');

    const {
      data: { id, updated_at: updatedAt },
    } = await templateRepository.upload(payload);

    endLoading('uploadTemplate');

    return { id, updatedAt };
  };

  const updateTemplate = async (payload: { id: number; html: string }) => {
    startLoading('updateTemplate');

    await templateRepository.update(payload);

    endLoading('updateTemplate');
  };

  const deleteTemplate = async (payload: { id: number }) => {
    startLoading('deleteTemplate');

    await templateRepository.delete(payload);

    await getAllTemplates();

    endLoading('deleteTemplate');
  };

  const attachTemplatesToContract = async (payload: {
    contractId: string;
    templateIds: number[];
  }) => {
    startLoading('attachTemplatesToContract');

    await templateRepository.attachToContract(payload);

    await listDocuments(payload.contractId);

    endLoading('attachTemplatesToContract');
  };

  const removeTemplateFromContract = async (payload: {
    templateId: number;
    contractId: string;
  }) => {
    startLoading(`removeTemplateFromContract--${payload.templateId}`);

    await templateRepository.removeFromContract(payload);

    await getTemplatesForContract(payload.contractId);

    endLoading(`removeTemplateFromContract--${payload.templateId}`);
  };

  const getTemplatesForContract = async (contractId: string) => {
    startLoading('getTemplatesForContract');

    const response = await templateRepository.getAllForContract(contractId);

    endLoading('getTemplatesForContract');

    contractTemplates.value =
      response.data.data.map((template) => ({
        ...template,
        is_template: template.is_template === 1,
      })) || [];
  };

  return {
    getTemplatesForContract,
    templates,
    getAllTemplates,
    getTemplate,
    uploadTemplate,
    updateTemplate,
    deleteTemplate,
    attachTemplatesToContract,
    removeTemplateFromContract,
  };
});
