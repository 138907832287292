import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', () => {
  const loading = ref(true);

  const routeLoader = ref('');

  const showHero = ref(false);

  const showExpirationDialog = ref(false);

  const showBusinessFeaturesDialog = ref(false);

  return {
    loading,
    routeLoader,
    showHero,
    showExpirationDialog,
    showBusinessFeaturesDialog,
  };
});
