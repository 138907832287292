import Client from '@/repositories/clients/fetchClient';

const resource = 'tour';

export const tourRepository = {
  getAll: () =>
    Client.get<{ success: boolean; data: { tour_name: string }[] }>(resource),

  markAsSeen: (tourName: string) => Client.post(resource, { tourName }),
};
