import Client from '@/repositories/clients/fetchClient';
import type {
  CreateMagicLinkPayload,
  SendLinksPayload,
  TokenLoginPayload,
  ValidateMagicLinkPayload,
  ValidateMagicLinkResponse,
} from '@/types/authorization';

const resource = 'magic-link';

export const magicLinkRepository = {
  create: (payload: CreateMagicLinkPayload) =>
    Client.post<{ success: boolean }>(resource, payload),
  sendLinks: (payload: SendLinksPayload) =>
    Client.post(`${resource}/send-links`, payload),
  tokenLogin: (payload: TokenLoginPayload) =>
    Client.post<{ valid: boolean }>(`${resource}/token-login`, payload),
  validate: (payload: ValidateMagicLinkPayload) =>
    Client.post<ValidateMagicLinkResponse>(`${resource}/validate`, payload),
};
