import Client from '@/repositories/clients/fetchClient';

const resource = 'nets';

export const netsRepository = {
  getLink: (contractId: string) =>
    Client.get<{ link: string }>(`${resource}/get-link/${contractId}`),

  getIdToken: (code: string, state: string) =>
    Client.get(
      `${resource}/get-id-token/${state}?code=${encodeURIComponent(code)}`,
    ),
};
