<template>
  <div
    class="mx-auto flex w-full max-w-screen-3xl flex-wrap items-start gap-2 px-4 pt-2 sm:px-4 md:px-8"
  >
    <div class="flex-1 pt-2 md:pt-0">
      <div>
        <sovi-brand-title
          :size="4"
          class="!max-h-[clamp(48px,10vw,98px)] min-h-14"
          hide-subtitle-in-mobile
          @click="onLogoClick()"
        />
      </div>
    </div>

    <div
      ref="toolbarButtonsRef"
      data-step="settings"
      class="flex items-center gap-2"
      :class="{
        'pointer-events-none z-[11] -mx-2 rounded-full bg-light-200 px-2 transition-all dark:bg-dark-700':
          isStepActive('welcomeTour', 'settings'),
      }"
    >
      <sovi-header-credits
        v-if="isActionsVisible && !route.meta.hideHeaderActions"
      />

      <sovi-event-list
        v-if="isActionsVisible && !route.meta.hideHeaderActions"
      />

      <sovi-header-settings />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useOnboardingStore } from '@/stores/onboarding';

const { isStepActive } = useOnboardingStore();

const toolbarButtonsRef = ref<HTMLElement | null>(null);

const route = useRoute();

const router = useRouter();

const isActionsVisible = computed(() => {
  return !['Verify'].includes(route.name as string);
});

const onLogoClick = () => {
  if (route.name === 'Contracts' && !route.params.folderId) {
    return;
  }

  router.push({ name: 'Contracts' });
};
</script>

<style lang="scss">
.sovi-header {
  &__toolbar {
    .no-wrap {
      flex-wrap: nowrap !important;
    }

    .v-input--selection-controls {
      margin-top: 0 !important;
    }

    .header-actions {
      &.mobile {
        margin-top: -5px;
      }
    }
  }
}

.sovi-menu {
  position: absolute;
  max-width: 600px !important;
  min-width: 300px !important;

  // @include mq($until: md) {
  //   left: 50% !important;
  //   top: 52px !important;
  //   transform: translateX(-50%);
  //   width: calc(100svw - 32px);
  // }
}
</style>

<i18n lang="json">
{
  "fi": {
    "logout": "Kirjaudu ulos",
    "billingHistory": "Laskutushistoria",
    "subtitle": "Tehdään sopimus",
    "companySettings": "Yrityksen hallinta",
    "credits": "Allekirjoituskrediitit",
    "creditsLeft": "{credit} allekirjoituskrediitti jäljellä | {credit} allekirjoituskrediittiä jäljellä",
    "buyMore": "Osta",
    "theme": "Teema",
    "language": "Kieli",
    "fi": "Suomi",
    "en": "English",
    "themes": {
      "light": "Vaalea",
      "dark": "Tumma"
    },
    "tour": {
      "title": "Muut toiminnot",
      "content": {
        "credits": "Osta allekirjoituskrediittejä",
        "events": "Näytä tapahtumat",
        "settings": "Muokkaa asetuksia"
      }
    }
  },
  "en": {
    "logout": "Logout",
    "billingHistory": "Billing history",
    "subtitle": "Reach an agreement",
    "companySettings": "Company settings",
    "credits": "Signature credits",
    "creditsLeft": "{credit} signature credit left | {credit} signature credits left",
    "buyMore": "Buy",
    "theme": "Theme",
    "language": "Language",
    "fi": "Suomi",
    "en": "English",
    "themes": {
      "light": "Light",
      "dark": "Dark"
    },
    "tour": {
      "title": "Other actions",
      "content": {
        "credits": "Buy signature credits",
        "events": "View events",
        "settings": "Manage settings"
      }
    }
  }
}
</i18n>
